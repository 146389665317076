import { Fragment } from 'react'
import {
  AtButton,
  AtImage,
  AtImageProps,
  AtIframe,
  AtInfoItem,
  AtLink,
  AtLinkProps,
  getRichTextNode,
  MlBanner,
  MlBannerOverlay,
  MlBannerOverlayVariant,
  MlBeautyBanner,
  MlCard,
  MlContact,
  MlContentStrip,
  MlContentStripVariant,
  MlHeading,
  MlInfo,
  MlPullQuote,
  MlRichText,
  MlVertical,
  MlVideo,
  MlVideoVariant,
  OrCollapse,
  OrCollapseContainer,
  OrContainer,
  OrForm,
  OrStepper,
  OrTabs,
  toKebabCase,
  MlVideoProps,
} from '@curran-catalog/curran-atomic-library'

import {
  AssetSource,
  normalizeAsset,
  normalizeButton,
  normalizeCollapse,
  normalizeCollapseContainer,
  normalizeContentStrip,
  normalizeFormField,
  normalizeInfoItem,
  normalizeLink,
  normalizeOrStepper,
  normalizeOrTabs,
  useNormalizeContact,
} from '../normalize'
import { getWrapper } from '../get-wrapper'
import { environment } from '@config/environment'
import { submitForm } from '@services/ecommerce/submit-form'
import { validatePriorityImage } from '@utils/next-image-wrapper'
import {
  ContainerBlockContentType,
  ContentfulAtButton,
  ContentfulAtInfoItem,
  ContentfulAtLink,
  ContentfulBlock,
  ContentfulCloudinaryAsset,
  ContentfulMlBanner,
  ContentfulMlBannerOverlay,
  ContentfulMlBeautyBanner,
  ContentfulMlCard,
  ContentfulMlContact,
  ContentfulMlContentStrip,
  ContentfulMlHeading,
  ContentfulMlInfo,
  ContentfulMlPullquote,
  ContentfulMlRichText,
  ContentfulMlVertical,
  ContentfulMlVideo,
  ContentfulOrCollapse,
  ContentfulOrCollapseContainer,
  ContentfulOrContainer,
  ContentfulOrForm,
  ContentfulOrStepper,
  ContentfulOrTabs,
  ContentfulRenderer,
  ContentType,
} from 'types'
import { normalizeRichText } from '@utils/normalize/rich-text'
import { ContentfulComponentWithImages, DEFAULT_VIDEO_URL } from '@utils/common'
import { detectSiteDomain } from '@utils/detect-site-domain'
import { sendProductMeasureAnalyticsEvent } from '@utils/analytics/events/measuring-product/send-event'
import { ProductMeasureEventTypes } from '../../types/analytics/analytics-data'
import { ContentfulAtIframe } from '../../types/contentful/content-model/atoms/at-iframe'

export type ServicesToSendType = {
  [key: string]: { enabled: boolean; data: { [key: string]: string } }
}

export const toElementKey = ({ index, block }: { index?: string; block: { CONTENTFUL_ID?: string } }) =>
  index && block.CONTENTFUL_ID ? `${index}-${block.CONTENTFUL_ID}` : undefined

export const renderContentfulMlHeading: ContentfulRenderer = ({ block, index }) => {
  const { title, description, textAlignment, hasUnderline, paddingX, paddingY, headingSize, name } =
    block as ContentfulMlHeading

  return (
    <div
      id={toKebabCase(name)}
      key={toElementKey({ block, index })}
      className={`mx-auto tw-container ${paddingX ? '' : 'px-2'}`}
    >
      <MlHeading
        title={title}
        description={description}
        textAlignment={textAlignment}
        hasUnderline={hasUnderline}
        headingSize={headingSize}
        paddingX={paddingX}
        paddingY={paddingY}
      />
    </div>
  )
}

export const renderContentfulAtCloudinaryAsset: ContentfulRenderer = ({ block, index, containered = true }) => {
  const atCloudinaryAsset = block as ContentfulCloudinaryAsset
  const Wrapper = getWrapper(containered, false)
  return (
    <Wrapper key={toElementKey({ block, index })}>
      {atCloudinaryAsset.image.map((img) => (
        <AtImage
          key={img.public_id}
          {...normalizeAsset({ asset: { ...img, priority: validatePriorityImage({ index }) } })}
          className="w-fit mx-auto"
        />
      ))}
    </Wrapper>
  )
}

export const renderContentfulAtLink: ContentfulRenderer = ({ block, index }) => {
  const linkNormalized = normalizeLink(block as ContentfulAtLink, validatePriorityImage({ index }))

  return linkNormalized.actionUrl ? (
    <AtLink key={toElementKey({ block, index })} {...linkNormalized} />
  ) : (
    <AtImage key={toElementKey({ block, index })} {...linkNormalized.image} />
  )
}

export const renderContentfulAtButton: ContentfulRenderer = ({ block, index }) => {
  const buttonNormalized = normalizeButton(block as ContentfulAtButton)

  return <AtButton key={toElementKey({ block, index })} {...buttonNormalized} />
}

export const renderContentfulAtIframe: ContentfulRenderer = ({ block, index }) => {
  const { src, height, mobileSrc } = block as ContentfulAtIframe
  return <AtIframe key={toElementKey({ block, index })} src={src} height={height} mobileSrc={mobileSrc} />
}

export const renderContentfulOrContainer: ContentfulRenderer = ({ block, index }) => {
  const container = block as ContentfulOrContainer
  const elements = getRenderedBlocks(container.blocks, false, index)

  return (
    <OrContainer
      id={toKebabCase(container.name)}
      name={toKebabCase(container.name)}
      key={toElementKey({ block, index })}
      layout={container.layout}
      title={container.title ?? ''}
      description={container.description ?? ''}
      headingAlignment={container.headingAlignment}
      showUnderline={container.showUnderline ?? false}
      showNavigation={container.showNavigation}
      showTopPadding={container.showTopPadding}
      columnsDesktop={container.columns}
      columnsTablet={container.columnsTablet}
      columnsMobile={container.columnsMobile}
      button={container.button ? normalizeButton(container.button) : undefined}
      elements={elements}
      variant={container.variant}
      headingSize={container.headingSize}
      containerClassName={block.CONTENT_TYPE === ContentType.OR_COLLAPSE ? 'gap-0' : 'gap-4'}
      isH1={container.isH1}
    />
  )
}

export const renderContentfulMlInfo: ContentfulRenderer = ({ block, index }) => {
  const info = block as ContentfulMlInfo

  const normItems = info?.items?.map((item) => normalizeInfoItem(item, validatePriorityImage({ index })))

  const Wrapper = getWrapper(true, false)

  return (
    <Wrapper key={toElementKey({ block, index })}>
      <MlInfo description={info.description} infoItems={normItems} />
    </Wrapper>
  )
}

export const renderContentfulMlVideo: ContentfulRenderer = ({ block, index, containered = true }) => {
  const { headingTitle, headingDescription, thumbnailImage, videoUrl, videoFile, variant, showThumbnail } =
    block as ContentfulMlVideo

  const thumbnail =
    Array.isArray(thumbnailImage) && thumbnailImage.length > 0
      ? normalizeAsset({
          asset: { ...thumbnailImage[0], priority: validatePriorityImage({ index }) },
          contentType: ContentfulComponentWithImages.mlVideo,
        })
      : undefined

  const Wrapper = getWrapper(containered, false)

  const url =
    variant === MlVideoVariant.VIDEO_URL
      ? videoUrl ?? DEFAULT_VIDEO_URL.URL
      : videoFile && videoFile.length > 0
      ? videoFile[0].secure_url
      : DEFAULT_VIDEO_URL.FILE

  return (
    <Wrapper key={toElementKey({ block, index })}>
      <MlHeading
        title={getRichTextNode(headingTitle, 'heading-2')}
        description={headingDescription ? getRichTextNode(headingDescription, 'paragraph') : undefined}
        className={headingTitle ? 'mb-3' : ''}
      />

      <MlVideo
        videoUrl={url}
        image={thumbnail}
        variant={showThumbnail ? MlVideoVariant.VIDEO_FILE_THUMBNAILS : variant}
      />
    </Wrapper>
  )
}

export const renderContentfulMlBanner: ContentfulRenderer = ({ block, index, containered = true }) => {
  const infoBanner = block as ContentfulMlBanner

  const imageLink = normalizeLink(infoBanner.imageLink, validatePriorityImage({ index }))
  const mobileImageLink = infoBanner.mobileImageLink
    ? normalizeLink(infoBanner.mobileImageLink, validatePriorityImage({ index }))
    : undefined
  const thumbnails = infoBanner?.thumbnailLinks?.map((imgLink) =>
    normalizeLink(imgLink, validatePriorityImage({ index })),
  )

  const Wrapper = getWrapper(containered, true, false)

  const normalizedLink = infoBanner.link ? normalizeLink(infoBanner.link, validatePriorityImage({ index })) : undefined

  let videoThumbnail
  let videoUrl
  if (infoBanner.video) {
    videoThumbnail =
      Array.isArray(infoBanner.video.thumbnailImage) && infoBanner.video.thumbnailImage.length > 0
        ? normalizeAsset({
            asset: { ...infoBanner.video.thumbnailImage[0], priority: validatePriorityImage({ index }) },
            contentType: ContentfulComponentWithImages.mlVideo,
          })
        : undefined

    videoUrl =
      infoBanner.video.variant === MlVideoVariant.VIDEO_URL
        ? infoBanner.video.videoUrl ?? DEFAULT_VIDEO_URL.URL
        : infoBanner.video.videoFile && infoBanner.video.videoFile.length > 0
        ? infoBanner.video.videoFile[0].secure_url
        : DEFAULT_VIDEO_URL.FILE
  }

  return (
    <Wrapper key={toElementKey({ block, index })}>
      <MlBanner
        id={toKebabCase(infoBanner.name)}
        variant={infoBanner.variant}
        content={{ text: infoBanner.content }}
        imageLink={imageLink}
        link={normalizedLink}
        video={
          infoBanner.video
            ? ({
                image: videoThumbnail,
                videoUrl: videoUrl,
                variant: infoBanner.video.variant,
                videoAttributes: {
                  controls: !!infoBanner.video.showThumbnail,
                  autoPlay: !infoBanner.video.showThumbnail,
                  poster: infoBanner.video.showThumbnail && videoThumbnail ? videoThumbnail.src : undefined,
                },
              } as MlVideoProps)
            : undefined
        }
        imageAspectRatio={infoBanner.imageAspectRatio}
        mobileImageLink={mobileImageLink}
        mobileAspectRatio={infoBanner.mobileAspectRatio}
        thumbnailLinks={thumbnails as AtLinkProps[]}
        imagePosition={infoBanner.imagePosition}
        preHeading={infoBanner.preHeading ? { text: infoBanner.preHeading } : undefined}
        button={infoBanner.button ? normalizeButton(infoBanner.button) : undefined}
        headingSize={infoBanner.headingSize}
        thumbnailsPosition={infoBanner.thumbnailsPosition}
      />
    </Wrapper>
  )
}

export const renderContentfulMlVertical: ContentfulRenderer = ({ block, index, trueIndex }) => {
  const vertical = block as ContentfulMlVertical

  const image = normalizeAsset({
    asset: { ...vertical.fileImage[0], priority: validatePriorityImage({ index }) },
    contentType: ContentfulComponentWithImages.mlVertical,
  })
  const link = normalizeLink(vertical?.link)

  const clickHandler = (index: number, objectID?: string) => {
    if (vertical.analyticsData && objectID) {
      const trueIndex = index ? index + 1 : 1
      sendProductMeasureAnalyticsEvent(vertical.analyticsData, ProductMeasureEventTypes.SELECT_ITEM, trueIndex)
      window.sessionStorage.setItem('productIndexID', JSON.stringify(trueIndex))
    }
  }

  const intersectionHandler = (index: number, objectID?: string) => {
    if (vertical.analyticsData && objectID) {
      const trueIndex = index ? index + 1 : 1
      sendProductMeasureAnalyticsEvent(vertical.analyticsData, ProductMeasureEventTypes.VIEW_ITEM_LIST, trueIndex)
    }
  }

  return (
    <MlVertical
      image={{ ...image }}
      key={toElementKey({ block, index })}
      link={link}
      variant={vertical.variant}
      objectID={vertical.analyticsData?.productId}
      index={Number(trueIndex)}
      clickHandler={clickHandler}
      intersectionHandler={intersectionHandler}
      uppercaseLink={vertical?.uppercaseLink}
    />
  )
}

export const renderContentfulMlCard: ContentfulRenderer = ({ block, index }) => {
  const card = block as ContentfulMlCard

  const image = card.fileImage
    ? normalizeAsset({
        asset: {
          ...card.fileImage[0],
          priority: validatePriorityImage({ index }),
        },
        contentType: ContentfulComponentWithImages.mlCard,
      })
    : undefined

  const hoverImage =
    Array.isArray(card.fileHoverImage) && card.fileHoverImage.length > 0
      ? normalizeAsset({
          asset: { ...card.fileHoverImage[0], priority: validatePriorityImage({ index }) },
          contentType: ContentfulComponentWithImages.mlCard,
        })
      : undefined

  const link = normalizeLink(card?.link, validatePriorityImage({ index }))
  const button = normalizeButton(card?.button)
  const secondaryButton = normalizeButton(card?.secondaryButton)

  // Apply inline style to override padding/margin if label is missing
  const linkStyles = link.label
    ? {}
    : {
        padding: '0', // Remove any padding when no label
        margin: '0', // Remove any margin when no label
        display: 'inline-block', // Prevent collapse
        border: '1px solid red', // Debugging border to visualize link area
      }

  return (
    <MlCard
      key={toElementKey({ block, index })}
      title={card.title}
      description={card.description && { text: card.description }}
      image={{ ...image }}
      hoverImage={hoverImage?.src ? { ...hoverImage } : undefined}
      link={link.actionUrl ? { ...link, style: linkStyles } : undefined}
      button={button.label ? button : undefined}
      secondaryButton={secondaryButton.label ? secondaryButton : undefined}
    />
  )
}

export const renderContentfulMlPullquote: ContentfulRenderer = ({ block, index }) => {
  const pullquote = block as ContentfulMlPullquote

  // Normalize the image asset for the pullquote
  const image = pullquote.fileImage
    ? normalizeAsset({
        asset: {
          ...pullquote.fileImage[0],
          priority: validatePriorityImage({ index }),
        },
        contentType: ContentfulComponentWithImages.mlPullQuote,
      })
    : undefined

  // Now pass 'text' as the description
  return (
    <MlPullQuote
      key={toElementKey({ block, index })}
      text={pullquote.description} // Pass description as text (since 'description' is rich text content)
      image={{ ...image }}
    />
  )
}

export const renderContentfulMlContentStrip: ContentfulRenderer = ({ block, index, containered = true }) => {
  const contentStripInfo = block as ContentfulMlContentStrip

  const Wrapper = getWrapper(containered, contentStripInfo.variant !== MlContentStripVariant.NOT_FOUND, false)

  // this is to support the ticket CUR-569 spacing only for contentStript default
  const className = contentStripInfo.variant === MlContentStripVariant.DEFAULT ? 'xl:tw-mx-6 lg:tw-mx-4 md:tw-mx-0' : ''

  return (
    <Wrapper key={toElementKey({ block, index })}>
      <MlContentStrip
        {...normalizeContentStrip(contentStripInfo, validatePriorityImage({ index }))}
        containerClassName={className}
        id={toKebabCase(contentStripInfo.name)}
        imagePositionMobile={contentStripInfo.imagePositionOnMobile}
      />
    </Wrapper>
  )
}

export const renderContentfulMlBeautyBanner: ContentfulRenderer = ({ block, index, containered = true }) => {
  const beautyBanner = block as ContentfulMlBeautyBanner

  const imagesNormalized = beautyBanner.imageLinks.map((image: ContentfulAtLink | ContentfulCloudinaryAsset) => {
    if (image.CONTENT_TYPE === ContentType.AT_LINK) {
      return normalizeLink(image, validatePriorityImage({ index }))
    } else {
      return normalizeAsset({
        asset: { ...image.image[0], priority: validatePriorityImage({ index }) },
        contentType: ContentfulComponentWithImages.mlBeautyBanner,
      })
    }
  })

  const Wrapper = getWrapper(containered, false)

  return (
    <Wrapper key={toElementKey({ block, index })} className="sm:px-0 pt-0">
      <MlBeautyBanner
        imageLinks={imagesNormalized as (AtLinkProps | AtImageProps)[]}
        id={toKebabCase(beautyBanner.name)}
      />
    </Wrapper>
  )
}

export const renderContentfulOrCollapse: ContentfulRenderer = ({ block, index }) => {
  const orCollapse = block as ContentfulOrCollapse

  return (
    <OrCollapse
      key={toElementKey({ block, index })}
      {...normalizeCollapse(orCollapse, undefined, validatePriorityImage({ index }))}
      idTarget={toKebabCase(orCollapse.name)}
    />
  )
}

export const renderContentfulOrContainerCollapse: ContentfulRenderer = ({ block, index }) => {
  const orCollapseContainerContent = block as ContentfulOrCollapseContainer

  return (
    <OrCollapseContainer
      key={toElementKey({ block, index })}
      {...normalizeCollapseContainer(orCollapseContainerContent)}
      customIdStorage={orCollapseContainerContent.CONTENTFUL_ID}
      id={toKebabCase(orCollapseContainerContent.name)}
    />
  )
}

export const renderContentfulMlContact: ContentfulRenderer = ({ block, index }) => {
  const mlContact = block as ContentfulMlContact

  const MlContactWrapper = () => {
    const normalizedContact = useNormalizeContact(mlContact)
    return <MlContact key={toElementKey({ block, index })} {...normalizedContact} />
  }
  return <MlContactWrapper />
}

export const renderContentfulMlRichText: ContentfulRenderer = ({ block, index, containered = true }) => {
  const mlRichtext = block as ContentfulMlRichText

  const Wrapper = getWrapper(containered, false)

  return (
    <Wrapper key={toElementKey({ block, index })}>
      <MlRichText {...normalizeRichText(mlRichtext)} />
    </Wrapper>
  )
}

export const renderContentfulOrTabs: ContentfulRenderer = ({ block, index }) => {
  const orTabs = block as ContentfulOrTabs

  const Wrapper = getWrapper(true, false)

  return (
    <Wrapper key={toElementKey({ block, index })}>
      <OrTabs {...normalizeOrTabs(orTabs)} />
    </Wrapper>
  )
}

export const renderContentfulAtInfoItem: ContentfulRenderer = ({ block, index }) => {
  const atInfoItem = block as ContentfulAtInfoItem
  const atInfoItemNormalized = normalizeInfoItem(atInfoItem, validatePriorityImage({ index }))

  return <AtInfoItem {...atInfoItemNormalized} key={toElementKey({ block, index })} />
}

export const renderContentfulOrForm: ContentfulRenderer = ({ block, index, containered = true }) => {
  const orForm = block as ContentfulOrForm
  const fieldsNormalized = orForm.formFields.map((field) => normalizeFormField(field))

  const { synsisalSite } = detectSiteDomain()

  const Wrapper = getWrapper(containered, false)

  const submitHandler = (form: FormData) => {
    return submitForm(form, orForm.servicesConfig, synsisalSite)
  }

  return (
    <Wrapper key={toElementKey({ block, index })}>
      <OrForm
        key={toElementKey({ block, index })}
        {...orForm}
        formFields={fieldsNormalized}
        submitButton={normalizeButton(orForm.submitButton)}
        onSubmit={submitHandler}
        reCaptchaKey={environment.recaptcha.siteKey}
        numberOfColumns={orForm.numberOfColumns}
        showModalOnSuccess={!synsisalSite}
      />
    </Wrapper>
  )
}

export const renderContentfulOrStepper: ContentfulRenderer = ({ block, index, containered = true }) => {
  const Wrapper = getWrapper(containered, false)

  return (
    <Wrapper key={toElementKey({ block, index })}>
      <OrStepper key={toElementKey({ block, index })} {...normalizeOrStepper(block as ContentfulOrStepper)} />
    </Wrapper>
  )
}

export const renderContentfulMlBannerOverlay: ContentfulRenderer = ({ block, index }) => {
  const mlBannerOverlay = block as ContentfulMlBannerOverlay

  const imageLink = normalizeLink(mlBannerOverlay.background as ContentfulAtLink, validatePriorityImage({ index }))
  const mobileImageLink = normalizeLink(
    mlBannerOverlay.backgroundMobile as ContentfulAtLink,
    validatePriorityImage({ index }),
  )

  const videoFile = (mlBannerOverlay.background as ContentfulMlVideo).videoFile
  const thumbnail = (mlBannerOverlay.background as ContentfulMlVideo).thumbnailImage
  const videoThumbnail = normalizeAsset({ asset: thumbnail ? thumbnail[0] : undefined })
  const videoFileMobile = (mlBannerOverlay.backgroundMobile as ContentfulMlVideo).videoFile
  const thumbnailMobile = (mlBannerOverlay.backgroundMobile as ContentfulMlVideo).thumbnailImage
  const videoThumbnailMobile = normalizeAsset({ asset: thumbnailMobile ? thumbnailMobile[0] : undefined })

  const variant = mlBannerOverlay.variant

  return (
    <MlBannerOverlay
      key={toElementKey({ block, index })}
      {...mlBannerOverlay}
      contentDesktop={{ text: mlBannerOverlay.contentDesktop }}
      contentMobile={{ text: mlBannerOverlay.contentMobile ?? mlBannerOverlay.contentDesktop }}
      background={{
        ...(variant === MlBannerOverlayVariant.IMAGE
          ? {
              ...imageLink,
              image: {
                ...imageLink.image,
                className: 'aspect-video bg-cover w-full hidden lg:block',
              },
            }
          : {
              videoUrl: (videoFile && videoFile[0]?.secure_url) || DEFAULT_VIDEO_URL.FILE,
              image: {
                src: videoThumbnail.src,
                alt: videoThumbnail.alt,
                source: AssetSource.CLOUDINARY,
              },
            }),
      }}
      backgroundMobile={{
        ...(variant === MlBannerOverlayVariant.IMAGE
          ? {
              ...mobileImageLink,
              image: {
                ...mobileImageLink.image,
                className: 'aspect-square bg-cover w-full block lg:hidden',
              },
            }
          : {
              videoUrl: (videoFileMobile && videoFileMobile[0]?.secure_url) || DEFAULT_VIDEO_URL.FILE,
              image: {
                src: videoThumbnailMobile.src,
                alt: videoThumbnailMobile.alt,
                source: AssetSource.CLOUDINARY,
              },
            }),
      }}
      hasOverlay={mlBannerOverlay.hasOverlay}
      overlayColor={mlBannerOverlay.color}
      ctaButton={mlBannerOverlay.ctaButton ? normalizeButton(mlBannerOverlay.ctaButton) : undefined}
      sectionClassName="md:min-h-[575px] min-h-[320px]"
    />
  )
}

export const contentfulBlocksRenderers: Record<ContainerBlockContentType, ContentfulRenderer> = {
  [ContentType.AT_CLOUDINARY_ASSET]: renderContentfulAtCloudinaryAsset,
  [ContentType.AT_IFRAME]: renderContentfulAtIframe,
  [ContentType.AT_LINK]: renderContentfulAtLink,
  [ContentType.AT_BUTTON]: renderContentfulAtButton,
  [ContentType.AT_INFO_ITEM]: renderContentfulAtInfoItem,
  [ContentType.ML_HEADING]: renderContentfulMlHeading,
  [ContentType.ML_INFO]: renderContentfulMlInfo,
  [ContentType.ML_VIDEO]: renderContentfulMlVideo,
  [ContentType.ML_BANNER]: renderContentfulMlBanner,
  [ContentType.ML_CARD]: renderContentfulMlCard,
  [ContentType.ML_PULL_QUOTE]: renderContentfulMlPullquote,
  [ContentType.ML_VERTICAL]: renderContentfulMlVertical,
  [ContentType.ML_CONTENT_STRIP]: renderContentfulMlContentStrip,
  [ContentType.ML_BEAUTY_BANNER]: renderContentfulMlBeautyBanner,
  [ContentType.ML_CONTACT]: renderContentfulMlContact,
  [ContentType.ML_RICH_TEXT]: renderContentfulMlRichText,
  [ContentType.ML_BANNER_OVERLAY]: renderContentfulMlBannerOverlay,
  [ContentType.OR_CONTAINER]: renderContentfulOrContainer,
  [ContentType.OR_COLLAPSE]: renderContentfulOrCollapse,
  [ContentType.OR_COLLAPSE_CONTAINER]: renderContentfulOrContainerCollapse,
  [ContentType.OR_TABS]: renderContentfulOrTabs,
  [ContentType.OR_FORM]: renderContentfulOrForm,
  [ContentType.OR_STEPPER]: renderContentfulOrStepper,
}

export const getRenderedBlocks = (blocks: ContentfulBlock[], containered?: boolean, parentIndex = '') => {
  return blocks
    .filter((block) => !!block)
    .map((block, index) => {
      const fullIndex = parentIndex ? `${parentIndex}.${index}` : `${index}`

      const renderer = contentfulBlocksRenderers[block.CONTENT_TYPE]
      if (renderer) {
        return renderer({ block, index: fullIndex, containered, trueIndex: index })
      }
      return <Fragment key={`empty-block-${fullIndex}`} />
    })
}
