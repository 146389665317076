export const RATE_LIMIT_MINUTES = 3
export const RATE_LIMIT_TIME = RATE_LIMIT_MINUTES * 60 * 1000

export const DEFAULT_INFO_ICON_COPY =
  'The base area rug price is calculated using a 4x6 rug and most economical binding. Pricing varies based on size and finishing options. Wall-to-wall pricing is calculated by the square yard.'

export const DEFAULT_VIDEO_URL = {
  URL: 'https://www.youtube.com/watch?v=PDj5HBgNhm4',
  FILE: 'https://res.cloudinary.com/curran-catalog/video/upload/eo_15,fl_clip,q_75,so_0/v1686158755/Marketing/Marketing%20Videos/Curran_Showroom_Video_gt3ptk.webm',
}

export const IMAGE_COMPONENTS_TRANSFORMATIONS: Record<ContentfulComponentWithImages, { width: number }> = {
  mlVideo: { width: 1276 },
  mlBanner: { width: 1276 },
  mlVertical: { width: 256 },
  mlCard: { width: 700 },
  mlContentStrip: { width: 1000 },
  mlPullQuote: { width: 700 },
  mlBeautyBanner: { width: 700 },
  imageGallery: { width: 700 },
  colorSelector: { width: 150 },
}

export enum ContentfulComponentWithImages {
  mlVideo = 'mlVideo',
  mlBanner = 'mlBanner',
  mlVertical = 'mlVertical',
  mlCard = 'mlCard',
  mlContentStrip = 'mlContentStrip',
  mlPullQuote = 'mlPullQuote',
  mlBeautyBanner = 'mlBeautyBanner',
  imageGallery = 'imageGallery',
  colorSelector = 'colorSelector',
}
